import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

const token = localStorage.getItem(`token`);

interface KYCData {
    grid_id: string;
    f_name: string;
    m_name: string;
    l_name: string;
    birth: string;
    id_card: string;
    email: string;
    country: string;
    id_card_url: string;
    passport_url: string;
    status: string;
    submit_date: string;
}

interface StatData {
    pending: string;
    processing: string;
    approved: string;
    rejected: string;
}

interface Admin {
    uname: string;
    uid: string;
    created_at: string;
    role: string;
    f_name: string | null;
    m_name: string | null;
    l_name: string | null;
    profile_image: string;
}

const VerifyUser = () => {
    const [kycData, setKycData] = useState<KYCData[]>([]);
    const [statData, setStatData] = useState<StatData>({
        pending: '0',
        processing: '0',
        approved: '0',
        rejected: '0',
    });
    const [showModalRejected, setShowModalRejected] = useState(false);
    const [targetUserUIDForRejected, setTargetUserUIDForRejected] = useState('');
    const [reasonRejected, setReasonRejected] = useState('');
    const [previewImageCentral, setPreviewImageCentral] = useState(false);
    const [imageURLPreview, setImageURLPreview] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [countryUserWhenChangeStatus, setCountryUserWhenChangeStatus] = useState('');
    const [adminData, setAdminData] = useState<Admin | null>(null);

    const statDisplay = [
        {
            title: 'กำลังรอ',
            count: statData?.pending,
        },
        {
            title: 'กำลังดำเนินการ',
            count: statData?.processing,
        },
        {
            title: 'อนุมัติ',
            count: statData?.approved,
        },
        {
            title: 'ปฏิเสธ',
            count: statData?.rejected,
        },
    ];

    useEffect(() => {
        getKYCData();
        getKYCStats();
    }, []);

    useEffect(() => {
        getKYCData();
    }, [searchValue, selectedStatus]);

    function getKYCData(): void {
        axios
            .get(`${process.env.REACT_APP_ADMIN_API}/kyc/table-data`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    name: searchValue,
                    status: selectedStatus,
                },
            })
            .then((res: any) => {
                const kycData = res.data.tableData;
                setKycData(kycData);
            })
            .catch((err: any) => {
                console.log(err);
            });
    }

    function getKYCStats(): void {
        axios
            .get(`${process.env.REACT_APP_ADMIN_API}/kyc/kyc-stats`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const kycData = res.data;
                setStatData({
                    pending: kycData.pending,
                    processing: kycData.processing,
                    approved: kycData.approved,
                    rejected: kycData.rejected,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function getAdminData(): any {
        const token = localStorage.getItem('token');

        axios
            .get(`${process.env.REACT_APP_ADMIN_API}/etc/my-profile`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const data = res.data;
                setAdminData(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        getAdminData();
    }, []);

    function updateStatusUser(userUID: string, newStatus: string, reason?: string): void {
        axios
            .post(
                `${process.env.REACT_APP_ADMIN_API}/kyc/update-kyc-status`,
                {
                    grid_id: userUID,
                    status: newStatus,
                    reason: reason,
                    approved_by: adminData?.uname,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(async (res: any) => {
                console.log(res.data);

                setCountryUserWhenChangeStatus('');
                getKYCStats();
                Swal.fire(``, `${res.data.message}`, 'success');
            })
            .catch((err: any) => {
                Swal.fire(``, `${err.response.data.Error}`, 'error');
            });

        setShowModalRejected(false);
    }

    function handleChangeStatus(index: number, newStatus: string, userUID: string, country: string): void {
        setCountryUserWhenChangeStatus(country);

        if (newStatus === 'rejected') {
            setShowModalRejected(true);
            setTargetUserUIDForRejected(userUID);
        } else if (newStatus === 'approved' || newStatus === 'processing') {
            updateStatusUser(userUID, newStatus);
        }


        console.log(countryUserWhenChangeStatus);
        const updatedKycData = [...kycData];
        kycData[index].status = newStatus;
        setKycData(updatedKycData);

    }

    function handleReasonRejected(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        const value = e.target.value;
        setReasonRejected(value);
    }

    function handleRejectVerifyUser(): void {
        updateStatusUser(targetUserUIDForRejected, 'rejected', reasonRejected);
    }

    function handleShowPreviewImage(imageURL: string) {
        setPreviewImageCentral(true);
        setImageURLPreview(imageURL);
    }

    function convertDateToDDMMYYYY(date: string): string {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const year = d.getFullYear();
        return `${day}/${month}/${year}`;
    }

    function inputValue(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        const value = e.target.value;
        setSearchValue(value);
    }

    function handleStatusChange(e: React.ChangeEvent<HTMLSelectElement>): void {
        const value = e.target.value;
        setSelectedStatus(value);
    }

    return (
        <main className="relative w-full h-full">
            {showModalRejected ? (
                <div className="flex flex-col absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[600px] w-8/12 z-50 bg-white p-4">
                    <div className="z-50 text-red-500 text-5xl absolute left-6 p-2.5 rounded-full top-2.5 cursor-pointer" onClick={() => setShowModalRejected(false)}>
                        X
                    </div>
                    <div className="relative flex justify-center items-center w-full h-full mx-auto">
                        <input
                            type="text"
                            placeholder="ระบุเหตุผล"
                            className="w-8/12 h-full outline-none p-2.5 mx-auto rounded-xl text-center"
                            onChange={(e) => handleReasonRejected(e)}
                        />
                        <div
                            onClick={() => {
                                handleRejectVerifyUser();
                            }}
                            className="absolute right-6 bottom-6 bg-red-500 text-white flex justify-center items-center p-2.5 rounded-full cursor-pointer"
                        >
                            ยืนยันการไม่อนุมัติ
                        </div>
                    </div>
                </div>
            ) : null}

            {previewImageCentral ? (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50" onClick={() => setPreviewImageCentral(false)}>
                    <img src={imageURLPreview} alt="Preview" className="max-w-[400px] max-h-[400px] md:max-w-[1200px] md:max-h-[1200px] object-contain" />
                </div>
            ) : null}

            <div className="w-full h-[200px] bg-purple-800">
                {/* Statistics display */}
                <div className="text-white h-full grid grid-cols-2 mt-16 md:grid-cols-4">
                    {statDisplay.map((data) => {
                        return (
                            <div className="w-full h-full flex flex-col justify-center items-center">
                                <h3>{data.title}</h3>
                                <span className="text-5xl">{data.count}</span>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="flex justify-center items-center w-full h-12 mt-4">
                <input type="text" placeholder="ค้นหา Keyword" className="h-12 w-full bg-gray-100 outline-0 text-center rounded-xl " onChange={(e) => inputValue(e)} />
                <select className="ml-4 h-12 bg-gray-100 rounded-xl" onChange={(e) => handleStatusChange(e)}>
                    <option value="">All Status</option>
                    <option value="pending">Pending</option>
                    <option value="processing">Processing</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                </select>
            </div>

            <div className="relative h-[500px] sm:rounded-lg overflow-y-auto mt-4">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase dark:text-gray-400">
                        <tr>
                            {/* Table headers */}
                            <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                                Grid ID
                            </th>
                            <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                                Submit date
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Full Name
                            </th>
                            <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                                Birthdate
                            </th>
                            <th scope="col" className="px-6 py-3">
                                ID Card
                            </th>
                            <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                                Email
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Country
                            </th>
                            <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                                ID Card
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Passport
                            </th>
                            <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {kycData.map(({ grid_id, submit_date, f_name, m_name, l_name, birth, id_card, email, country, id_card_url, passport_url, status }, index) => (
                            <tr key={index} className="border-b border-gray-200 dark:border-gray-700">
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">{grid_id}</td>
                                <td className="px-6 py-4">{`${submit_date}`}</td>
                                <td className="px-6 py-4">{`${f_name} ${m_name} ${l_name}`}</td>
                                <td className="px-6 py-4 bg-gray-50 dark:bg-gray-800">{convertDateToDDMMYYYY(birth)}</td>
                                <td className="px-6 py-4">{id_card}</td>
                                <td className="px-6 py-4 bg-gray-50 dark:bg-gray-800">{email}</td>
                                <td className="px-6 py-4">{country}</td>
                                <td className="px-6 py-4 bg-gray-50 dark:bg-gray-800">
                                    {id_card_url && <img onClick={() => handleShowPreviewImage(id_card_url)} src={id_card_url} alt="ID Card" className="h-16 cursor-pointer" />}
                                </td>
                                <td className="px-6 py-4">
                                    {passport_url && <img onClick={() => handleShowPreviewImage(passport_url)} src={passport_url} alt="Passport" className="h-16 cursor-pointer" />}
                                </td>
                                <td className="px-6 py-4">
                                    <select value={status} onChange={(e) => handleChangeStatus(index, e.target.value, grid_id, country)}>
                                        <option value="pending">Pending</option>
                                        <option value="processing">Processing</option>
                                        <option value="approved">Approved</option>
                                        <option value="rejected">Rejected</option>
                                    </select>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </main>
    );
};

export default VerifyUser;
