import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "./Modal"; // Import your Modal component

interface Package {
  package_id: number;
  name: string;
  cost: number;
  roi: string;
  max_active: string;
  max_passive: string;
  staking: string;
  created_at: string;
  sellable: boolean;
  tier: number;
  icon_image: string;
}

const LeaderPackage: React.FC = () => {
  const [packages, setPackages] = useState<Package[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [packageID, setPackageID] = useState(0);
  const [packageName , setPackageName] = useState('')
  const token = localStorage.getItem("token");

  const getListPackage = () => {
    axios
      .get(`${process.env.REACT_APP_ADMIN_API}/etc/list-package`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res: any) => {
        setPackages(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getListPackage();
  }, []);

  const handleAddUserClick = (package_id: number , package_name : string) => {
    setPackageID(package_id);
    setPackageName(package_name);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <main className={`w-full h-full ${isModalOpen ? "blurred" : ""}`}>
      <div className="grid grid-cols-1  w-full justify-center items-center h-[calc(100vh - 180px)] overflow-y-auto md:grid-cols-4 md:h-[900px]">
        {packages.map((pkg) => (
          <div
            key={pkg.package_id}
            className="w-11/12 max-w-[480px] m-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
          >
            <a href="#">
              <img
                className="p-8 rounded-t-lg"
                src={pkg.icon_image}
                alt={`${pkg.name} icon`}
              />
            </a>
            <div className="px-5 pb-5">
              <a href="#">
                <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                  {pkg.name}
                </h5>
              </a>
              <div className="border font-medium grid grid-rows-2 text-center p-1 mb-2.5 rounded-xl">
                <p>Max Active Capacity&nbsp; {`$${parseInt(pkg.max_active).toLocaleString()}`}</p>
                <p>Max Passive Capacity&nbsp;{`$${parseInt(pkg.max_passive).toLocaleString()}`}</p>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-3xl font-bold text-gray-900">
                  ${pkg.cost}
                </span>
                <button
                  onClick={() => handleAddUserClick(pkg.package_id , pkg.name)}
                  className="text-white bg-purple-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Add User
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {isModalOpen && (
        <Modal package_id={packageID} packageName={packageName}   closeModal={closeModal} />
      )}{" "}
    </main>
  );
};

export default LeaderPackage;
