import axios from "axios";
export default function LineNotify(message : string , token : string){
    const jwt = localStorage.getItem("token")

    axios.post(`${process.env.REACT_APP_ADMIN_API}/manage/send-line`,{
        token : token,
        message: message
    },{
        headers : {
            Authorization : `Bearer ${jwt}`
        }
    }).then((res)=>{
        console.log("Successfully Sent Line Notify")
    }).catch((err)=>{
        console.log(err)
    })
   return true
}

