import React, { useEffect } from "react";
import { useAuth } from "../auth/AuthControl";
import {
  MobileNav,
  Typography,
  Collapse,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  Card,
  IconButton,
} from "@material-tailwind/react";
import {
  UserCircleIcon,
  CodeBracketSquareIcon,
  Square3Stack3DIcon,
  ChevronDownIcon,
  PowerIcon,
  RocketLaunchIcon,
  Bars2Icon,
} from "@heroicons/react/24/solid";

const profileMenuItems = [
  {
    label: "Sign Out",
    icon: PowerIcon,
  },
];

const checkPermission = (permission: string, route: string): boolean => {
  const permissionMap: { [key: string]: string[] } = {
    superadmin: [
      '/',
      '/verify/kyc',
      '/add/ads',
      '/add/points',
      '/report/tickets',
      '/add/leader-package',
      '/add/staking-package',
      '/edit/wallet',
      '/profile/edit',
      '/create/account',
      '/queue/configuration',
      '/queue/priority'
    ],
    admin: [
      '/',
      '/verify/kyc',
      '/add/ads',
      '/report/tickets',
      '/add/leader-package',
      '/add/staking-package',
      '/edit/wallet',
      '/profile/edit',
    ],
    staff: [
      '/',
      '/add/ads',
      '/edit/wallet',
      '/profile/edit',
      '/profile/reset-2fa',
      '/verify/kyc'
    ],
  };

  return permissionMap[permission]?.includes(route) ?? false;
};

function ProfileMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { logout } = useAuth();

  const closeMenu = () => setIsMenuOpen(false);

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <MenuHandler>
        <Button
          variant="text"
          color="blue-gray"
          className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto"
        >
          <Avatar
            variant="circular"
            size="sm"
            alt="tania andrew"
            className="border border-gray-900 p-0.5"
            src="/imgs/logo.jpg"
          />
        </Button>
      </MenuHandler>
    </Menu>
  );
}

// nav list menu
const navListMenuItems = [
  {
    title: "Main Page",
    description: "All Menu",
    link: "/"
  },

  {
    title: "System KYC",
    description: "Check KYC",
    link: "/verify/kyc"
  },
  {
    title: "PopUp",
    description: "Add PopUp",
    link: "/add/ads"
  },
  {
    title: "Add Points",
    description: "Add Point To User",
    link: "/add/points"
  },
  {
    title: "Issue Ticket",
    description: "All Issue",
    link: "/report/tickets"
  },
  {
    title: "Add Leader Package",
    description: "Add Leader Package",
    link: "/add/leader-package"
  },
  {
    title: "Add Staking Package",
    description: "Add Staking Package",
    link: "/add/staking-package"
  },
  {
    title: "Edit Profile",
    description: "Edit profile",
    link: "/profile/edit"
  },
  {
    title: "Queue Configuration",
    description: "Check and update queue configuration",
    link: "/queue/configuration"
  },
  {
    title: "Queue priority",
    description: "Add/Remove priority queue",
    link: "/queue/priority"
  },
  {
    title: "Create Account",
    description: "Account",
    link: "/create/account"
  },
];

function NavListMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const permission = localStorage.getItem("permission") || '';

  const renderItems = navListMenuItems
    .filter(({ link }) => checkPermission(permission, link))
    .map(({ title, description, link }) => (
      <a href={link} key={title}>
        <MenuItem>
          <Typography variant="h6" color="blue-gray" className="mb-1 font-main">
            {title}
          </Typography>
          <Typography variant="small" color="gray" className="font-normal font-main">
            {description}
          </Typography>
        </MenuItem>
      </a>
    ));

  return (
    <React.Fragment>
      <Menu allowHover open={isMenuOpen} handler={setIsMenuOpen}>
        <MenuHandler>
          <Typography as="a" href="#" variant="small" className="font-normal">
            <MenuItem className="hidden items-center gap-2 font-medium text-blue-gray-900 lg:flex lg:rounded-full">
              <Square3Stack3DIcon className="h-[18px] w-[18px] text-blue-gray-500" />{" "}
              Pages{" "}
              <ChevronDownIcon
                strokeWidth={2}
                className={`h-3 w-3 transition-transform ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
            </MenuItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden w-[36rem] grid-cols-7 gap-3 overflow-visible lg:grid">
          <Card
            color="blue"
            shadow={false}
            variant="gradient"
            className="col-span-3 grid h-full w-full place-items-center rounded-md"
          >
            <RocketLaunchIcon strokeWidth={1} className="h-28 w-28" />
          </Card>
          <ul className="col-span-4 flex w-full flex-col gap-1">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <MenuItem className="flex items-center gap-2 font-medium text-blue-gray-900 lg:hidden">
        <Square3Stack3DIcon className="h-[18px] w-[18px] text-blue-gray-500" />{" "}
        เมนู{" "}
      </MenuItem>
      <ul className="ml-6 flex w-full flex-col gap-1 lg:hidden">
        {renderItems}
      </ul>
    </React.Fragment>
  );
}

// nav list component
const navListItems = [
  {
    label: "Account",
    icon: UserCircleIcon,
  },
  {
    label: "Docs",
    icon: CodeBracketSquareIcon,
  },
];

function NavList() {
  return (
    <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center ">
      <NavListMenu />
    </ul>
  );
}

export function ComplexNavbar() {
  const { isAuthenticated, login, logout } = useAuth();
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const currentUrl = window.location.href;

  const hiddenURLs = ['http://localhost:3000/auth/login'];
  
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsNavOpen(false),
    );
  }, []);

  if (hiddenURLs.includes(currentUrl)) {
    return null;
  }

  return (
    <div className="w-full mx-auto fixed right-0 top-0 max-w-screen-lg p-2 h-max lg:pl-6 bg-white md:hidden z-50 md:right-2 md:top-1.5">
      <div className="relative mx-auto flex items-center justify-between text-blue-gray-900 ">
        <Typography
          as="a"
          href="#"
          className="mr-4 ml-2 cursor-pointer py-1.5 font-medium font-main"
        >
          ระบบบริหารจัดการ
        </Typography>
        <div className="hidden lg:block">
          <NavList />
        </div>
        <IconButton
          size="sm"
          color="blue-gray"
          variant="text"
          onClick={() => setIsNavOpen(!isNavOpen)}
          className="ml-auto mr-2 lg:hidden"
        >
          <Bars2Icon className="h-6 w-6" />
        </IconButton>
        <Button size="sm" variant="text" onClick={() => logout()}>
          <span className="text-red-500">Logout</span>
        </Button>
        <ProfileMenu />
      </div>
      <Collapse open={isNavOpen} className="overflow-scroll">
        <NavList />
      </Collapse>
    </div>
  );
}
