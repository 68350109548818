import { useEffect, useState } from 'react';
import { RoutesPath } from './Router/RoutesPath';
import { useAuth } from './auth/AuthControl';
import { useLocation } from 'react-router-dom';
import { Login } from './components/auth/login/Login';
import axios from 'axios';

const INACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 minutes in milliseconds

function App() {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Axios interceptor to handle unauthorized errors
    const interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          window.location.replace("/auth/login");
        }
        return Promise.reject(error);
      }
    );

    // Cleanup interceptor
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  useEffect(() => {
    // Function to reset the timer
    const resetTimer = () => {
      if (timer) {
        clearTimeout(timer);
      }
      setTimer(setTimeout(() => {
        window.location.replace("/auth/login");
      }, INACTIVITY_TIMEOUT));
    };

    // Events to listen to for user activity
    const events = ['mousemove', 'keydown', 'scroll', 'click'];

    // Attach event listeners
    events.forEach(event => {
      window.addEventListener(event, resetTimer);
    });

    // Initialize the timer
    resetTimer();

    // Cleanup event listeners on unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      events.forEach(event => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, []);

  const bypassPage = ['/kyc/approval',  '/register/otp'];
  const currentPath = location.pathname;
  const isBypassPath = bypassPage.some(page => currentPath.includes(page));

  if (!isAuthenticated && !isBypassPath) {
    return <Login />;
  }

  return (
    <div className="App flex">
      <RoutesPath />
    </div>
  );
}

export default App;
