// import React from "react";
import {
    Card,
    Typography,
    List,
    ListItem,
    ListItemPrefix,
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import {
    GlobeAltIcon,
    PowerIcon,
    CommandLineIcon,
    WalletIcon,
    StarIcon,
    HandThumbDownIcon,
    TagIcon,
    FilmIcon,
    UserCircleIcon,
    LockClosedIcon,
    ArrowPathIcon,
    ArrowLeftOnRectangleIcon
} from "@heroicons/react/24/solid";
import {
    ChevronDownIcon,
} from "@heroicons/react/24/outline";
import "../index.css"
import { Link } from "react-router-dom";
import { useSidebar } from '../context/SidebarContext';
import { useAuth } from "../auth/AuthControl";
// import { Login } from "../components/auth/login/Login";

const checkPermission = (permission: string, route: string): boolean => {
  const permissionMap: { [key: string]: string[] } = {
    superadmin: [
      '/verify/kyc',
      '/add/ads',
      '/add/points',
      '/report/tickets',
      '/add/leader-package',
      '/add/staking-package',
      '/edit/wallet',
      '/profile/edit',
      '/profile/reset-2fa',
      '/create/account',
      '/queue/configuration',
      '/queue/priority'
    ],
    admin: [
      '/verify/kyc',
      '/add/ads',
      '/report/tickets',
      '/add/leader-package',
      '/add/staking-package',
      '/edit/wallet',
      '/profile/edit',
      '/profile/reset-2fa'
    ],
    staff: [
      '/add/ads',
      '/edit/wallet',
      '/profile/edit',
      '/profile/reset-2fa',
      '/verify/kyc',
    ],
  };

  return permissionMap[permission]?.includes(route) ?? false;
};

export function SidebarWithLogo() {
    const {isAuthenticated , login , logout} = useAuth();
    const { open, setOpen } = useSidebar();
    // const [openAlert, setOpenAlert] = React.useState(true);
    const permission = localStorage.getItem("permission") || '';
    
    const handleOpen = (value: any) => {
        setOpen(open === value ? 0 : value);
    }

    return (
        <Card className="h-[calc(100vh)] relative w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5  hidden md:flex z-50">
            <div className="mb-2 flex items-center gap-4 p-4">
                <img src="/imgs/logo.jpg" alt="brand" className="h-8 w-8" />
                <Typography variant="h5" color="blue-gray" className="font-main">
                Grit Enterprise 
                </Typography >
            </div>
            <List>
                <Accordion
                    open={open === 1}
                    icon={
                        <ChevronDownIcon
                            strokeWidth={2.5}
                            className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""}`}
                        />
                    }
                >
                    <ListItem className="p-0" selected={open === 1}>
                        <AccordionHeader onClick={() => handleOpen(1)} className="border-b-0 p-3">
                            <ListItemPrefix>
                                <GlobeAltIcon className="h-5 w-5" />
                            </ListItemPrefix>
                            <Typography color="blue-gray" className="mr-auto font-normal font-main">
                                Website
                            </Typography >
                        </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1">

                        <List className="p-0  ml-2.5">
                            
                              <Link to={`/`}>
                                <ListItem >
                                    <ListItemPrefix>
                                        <CommandLineIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    หน้าหลัก
                                </ListItem>
                              </Link>
                     
                        </List>

                        <List className="p-0  ml-2.5">
                            {checkPermission(permission, '/verify/kyc') && (
                              <Link to={`/verify/kyc`}>
                                <ListItem >
                                    <ListItemPrefix>
                                    <UserCircleIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    รายการ KYC
                                </ListItem>
                              </Link>
                            )}
                        </List>

                        <List className="p-0  ml-2.5">
                            {checkPermission(permission, '/add/ads') && (
                              <Link to={`/add/ads`}>
                                <ListItem >
                                    <ListItemPrefix>
                                    <FilmIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    Ads
                                </ListItem>
                              </Link>
                            )}
                        </List>

                        <List className="p-0  ml-2.5">
                            {checkPermission(permission, '/add/points') && (
                              <Link to={`/add/points`}>
                                <ListItem >
                                    <ListItemPrefix>
                                        <StarIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    เติมแต้ม
                                </ListItem>
                              </Link>
                            )}
                        </List>

                        <List className="p-0  ml-2.5">
                            {checkPermission(permission, '/report/tickets') && (
                              <Link to={`/report/tickets`}>
                                <ListItem >
                                    <ListItemPrefix>
                                        <HandThumbDownIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    ปัญหา Ticket
                                </ListItem>
                              </Link>
                            )}
                        </List>

                        <List className="p-0  ml-2.5">
                            {checkPermission(permission, '/add/leader-package') && (
                              <Link to={`/add/leader-package`}>
                                <ListItem >
                                    <ListItemPrefix>
                                        <TagIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    Leader Package
                                </ListItem>
                              </Link>
                            )}
                        </List>

                        <List className="p-0  ml-2.5">
                            {checkPermission(permission, '/add/staking-package') && (
                              <Link to={`/add/staking-package`}>
                                <ListItem >
                                    <ListItemPrefix>
                                        <TagIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    Staking Package
                                </ListItem>
                              </Link>
                            )}
                        </List>

                        <List className="p-0  ml-2.5">
                            {checkPermission(permission, '/profile/edit') && (
                              <Link to={`/profile/edit`}>
                                <ListItem >
                                    <ListItemPrefix>
                                        <WalletIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    Edit Profile
                                </ListItem>
                              </Link>
                            )}
                        </List>

                        <List className="p-0  ml-2.5">
                            {checkPermission(permission, '/profile/reset-2fa') && (
                              <Link to={`/profile/reset-2fa`}>
                                <ListItem >
                                    <ListItemPrefix>
                                        <LockClosedIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    Reset 2FA Profile
                                </ListItem>
                              </Link>
                            )}
                        </List>

                        <List className="p-0  ml-2.5">
                            {checkPermission(permission, '/queue/configuration') && (
                              <Link to={`/queue/configuration`}>
                                <ListItem >
                                    <ListItemPrefix>
                                        <ArrowPathIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    Queue Configuration
                                </ListItem>
                              </Link>
                            )}
                        </List>

                        <List className="p-0  ml-2.5">
                            {checkPermission(permission, '/queue/priority') && (
                              <Link to={`/queue/priority`}>
                                <ListItem >
                                    <ListItemPrefix>
                                        <ArrowLeftOnRectangleIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    Queue Priority
                                </ListItem>
                              </Link>
                            )}
                        </List>

                        <List className="p-0  ml-2.5">
                            {checkPermission(permission, '/create/account') && (
                              <Link to={`/create/account`}>
                                <ListItem >
                                    <ListItemPrefix>
                                        <UserCircleIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    Create Account
                                </ListItem>
                              </Link>
                            )}
                        </List>
                    </AccordionBody>
                </Accordion>

                <hr className="my-2 border-blue-gray-50" />
                <ListItem onClick={()=>logout()}>
                    <ListItemPrefix>
                        <PowerIcon className="h-5 w-5" />
                    </ListItemPrefix>
                    Log Out
                </ListItem>
            </List>
        </Card>
    );
}
