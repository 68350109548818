import axios from "axios";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

type Status = 'pending' | 'approved' | 'rejected';

type KYCData = {
    birth: string;
    country: string;
    email: string;
    name: string;
    f_name: string;
    l_name: string;
    m_name: string;
    grid_id: string;
    id_card: string;
    id_card_url: string;
    passport_url: string;
    reason: string;
    submit_date: string;
    status: Status;
};

export default function KYCAcpproval() {
    const { code } = useParams();

  const [kycData, setKycData] = useState<KYCData>();
  const [errorMessage, setErrorMessage] = useState<String>("");
  const [previewImageCentral, setPreviewImageCentral] = useState(false);
  const [imageURLPreview, setImageURLPreview] = useState("");

    useEffect(() => {
        getKYCApproval();
    }, []);

  function getKYCApproval() {
    axios
      .get(
        `${process.env.REACT_APP_MAIN_API}/customer/kyc/approval/info?code=${code}`
      )
      .then((res) => {
        setErrorMessage("");
        const data = res.data.submission;
        setKycData({
          ...res.data.submission,
          name: `${data.f_name}${data.m_name ? " " : ""}${data.m_name} ${
            data.l_name
          }`,
        });
      })
      .catch((err) => {
        setErrorMessage(
          "Unable to process data, please access and approve KYC on the back-office website."
        );
        console.error("Error fetching data:", err);
      });
  }

  function approveKYC(isApproved: boolean) {
    Swal.fire({
      title: "Update KYC Status",
      text: "ยืนยันเพื่อ Update KYC Status",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${process.env.REACT_APP_ADMIN_API}/kyc/update-kyc-status`, {
            grid_id: kycData?.grid_id,
            status: isApproved ? "approved" : "rejected",
            approved_by: "KYC Link",
          })
          .then((res) => {
            Swal.fire({
              text: "Update สถานะสำเร็จ",
              icon: "success",
            });
          })
          .catch((err) => {
            console.error("Error fetching data:", err);
          });
      }
    });
  }

  function handleShowPreviewImage(imageURL: string) {
    setPreviewImageCentral(true);
    setImageURLPreview(imageURL);
  }

  function convertDateToDDMMYYYY(date: string | undefined): string {
    if (date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, "0");
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    }

    return "";
  }

  function formatDate(date: string) {
    const options: any = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(
      new Date(date).getTime()
    );
  }

  return (
    <main className="w-full mx-auto h-screen bg-cover bg-center">
      {previewImageCentral ? (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
          onClick={() => setPreviewImageCentral(false)}
        >
          <img
            src={imageURLPreview}
            alt="Preview"
            className="max-w-[400px] max-h-[400px] md:max-w-[1200px] md:max-h-[1200px] object-contain"
          />
        </div>
      ) : null}

      {errorMessage && (
        <div className="h-auto mx-auto mt-16 p-4 w-10/12 md:w-1/2">
          <div className="border h-max p-4 rounded-lg mb-4 bg-white">
            <h2 className="text-xl font-bold pb-1">
              {" "}
              {errorMessage.toUpperCase()}
            </h2>
          </div>
        </div>
      )}

      {kycData && (
        <div className="h-auto mx-auto mt-16 p-4 w-10/12 md:w-1/2">
          <div className="border h-max p-4 rounded-lg mb-4 bg-white">
            <h2 className="text-xl font-bold pb-1">KYC REQUEST</h2>
            <p className="text-gray-700">หมายเลขธุรกรรม: {code}</p>
            <p className="my-2">NAME</p>
            <input
              type="text"
              className="h-12 w-full bg-gray-100 outline-0 text-left rounded-xl px-4"
              value={kycData?.name}
              disabled
            />
            <p className="my-2">GRIT ID</p>
            <input
              type="text"
              className="h-12 w-full bg-gray-100 outline-0 text-left rounded-xl px-4"
              value={kycData?.grid_id}
              disabled
            />
            <p className="my-2">EMAIL</p>
            <input
              type="text"
              className="h-12 w-full bg-gray-100 outline-0 text-left rounded-xl px-4"
              value={kycData?.email}
              disabled
            />
            <p className="my-2">BIRTH DATE</p>
            <input
              type="text"
              className="h-12 w-full bg-gray-100 outline-0 text-left rounded-xl px-4"
              value={convertDateToDDMMYYYY(kycData?.birth)}
              disabled
            />
            <p className="my-2">COUNTRY</p>
            <input
              type="text"
              className="h-12 w-full bg-gray-100 outline-0 text-left rounded-xl px-4"
              value={kycData?.country}
              disabled
            />
            {kycData.passport_url && (
              <>
                <p className="my-2">PASSPORT IMAGE</p>
                <img
                  onClick={() => handleShowPreviewImage(kycData.passport_url)}
                  src={kycData?.passport_url}
                  alt="Passport"
                  className="h-16 cursor-pointer"
                />
              </>
            )}
            {kycData.id_card_url && (
              <>
                <p className="my-2">ID CARD IMAGE</p>
                <img
                  onClick={() => handleShowPreviewImage(kycData.id_card_url)}
                  src={kycData?.id_card_url}
                  alt="ID Card"
                  className="h-16 cursor-pointer"
                />
              </>
            )}
            <p className="text-gray-700 mt-2">
              วันที่และเวลาที่ยื่น: {formatDate(kycData?.submit_date)}
            </p>
            <div className="mt-2 flex">
              <button
                className="h-12 flex-1 bg-green-500 text-white rounded-xl"
                onClick={() => approveKYC(true)}
              >
                APPROVED
              </button>
              <button
                className="h-12 flex-1 bg-red-500 text-white rounded-xl ml-2"
                onClick={() => approveKYC(false)}
              >
                REJECTED
              </button>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}
