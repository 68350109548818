import axios from "axios";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

type registerData = {
    country_code: string;
    country_name: string;
    email: string;
    email_verification: emailVerification;
    country: string;
    referral: string;
    user_id: string;
    username: string;
    otpData: string;
};

type emailVerification = {
    otp: string;
    otpRef: string;
    status: boolean;
    otpSendDate: string;
}

export default function BypassOTP() {
    const { code } = useParams();

  const [registerData, setRegisterData] = useState<registerData>();
  const [errorMessage, setErrorMessage] = useState<String>("");

    useEffect(() => {
        getUserRegisteredData();
    }, []);

  function getUserRegisteredData() {
    axios
      .get(`${process.env.REACT_APP_MAIN_API}/customer/register/otp/info?code=${code}`)
      .then((res) => {
        setErrorMessage("");
        const data = res.data.data;
        if(data.email_verification.status) {
            setErrorMessage(
                "Unable to process data, this user already passed the OTP process."
            );
        } else {
            setRegisterData({
                ...data,
                country: `${data.country_name} (${data.country_code})`,
                otpData: `${data.email_verification.otp} (${data.email_verification.otpRef})`
            });
        }
        
      })
      .catch((err) => {
        setErrorMessage(
          "Unable to process data, Access code not found or expired."
        );
        console.error("Error fetching data:", err);
      });
  }

  function bypassOTP() {
    Swal.fire({
      title: "Update OTP Status",
      text: "ยืนยันเพื่อ Bypass OTP Status",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${process.env.REACT_APP_MAIN_API}/customer/register/otp/bypass`, {
            user_id: registerData?.user_id
          })
          .then((res) => {
            Swal.fire({
              text: "Update สถานะสำเร็จ",
              icon: "success",
            });
          })
          .catch((err) => {
            console.error("Error fetching data:", err);
          });
      }
    });
  }

  return (
    <main className="w-full mx-auto h-screen bg-cover bg-center">
      {errorMessage && (
        <div className="h-auto mx-auto mt-16 p-4 w-10/12 md:w-1/2">
          <div className="border h-max p-4 rounded-lg mb-4 bg-white">
            <h2 className="text-xl font-bold pb-1">
              {" "}
              {errorMessage.toUpperCase()}
            </h2>
          </div>
        </div>
      )}

      {registerData && (
        <div className="h-auto mx-auto mt-16 p-4 w-10/12 md:w-1/2">
          <div className="border h-max p-4 rounded-lg mb-4 bg-white">
            <h2 className="text-xl font-bold pb-1">REGISTERED DATA</h2>
            <p className="text-gray-700">หมายเลขธุรกรรม: {code}</p>
            <p className="my-2">USER ID</p>
            <input
              type="text"
              className="h-12 w-full bg-gray-100 outline-0 text-left rounded-xl px-4"
              value={registerData?.user_id}
              disabled
            />
            <p className="my-2">USERNAME</p>
            <input
              type="text"
              className="h-12 w-full bg-gray-100 outline-0 text-left rounded-xl px-4"
              value={registerData?.username}
              disabled
            />
            <p className="my-2">EMAIL</p>
            <input
              type="text"
              className="h-12 w-full bg-gray-100 outline-0 text-left rounded-xl px-4"
              value={registerData?.email}
              disabled
            />
            <p className="my-2">COUNTRY</p>
            <input
              type="text"
              className="h-12 w-full bg-gray-100 outline-0 text-left rounded-xl px-4"
              value={registerData?.country}
              disabled
            />
            <p className="my-2">OTP</p>
            <input
              type="text"
              className="h-12 w-full bg-gray-100 outline-0 text-left rounded-xl px-4"
              value={registerData?.otpData}
              disabled
            />
            <div className="mt-4 flex">
              <button
                className="h-12 flex-1 bg-green-500 text-white rounded-xl"
                onClick={() => bypassOTP()}
              >
                BYPASS OTP PROCESS
              </button>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}
