import { useState, useCallback } from 'react';

export const useAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        const token = localStorage.getItem("token");
        const permission = localStorage.getItem("permission");

        return Boolean(token && permission);
    });

    const login = useCallback(() => {
        setIsAuthenticated(true);
    }, []);

    const logout = useCallback(() => {
        setIsAuthenticated(false);
        localStorage.removeItem("token");
        localStorage.removeItem("permission");
        window.location.replace('/auth/login')
    }, []);

    return { isAuthenticated, login, logout };
};
