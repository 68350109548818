import React, { useState } from 'react';
import { PuffLoader } from 'react-spinners';
import axios from 'axios';
import Swal from 'sweetalert2';

export function Login(): JSX.Element {
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const backgroundImageUrl =
        "url('https://images.unsplash.com/photo-1451187580459-43490279c0fa?q=80&w=1172&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')"; // Replace 'your-image-url.jpg' with your actual image URL

    function handleValidatePermission() {
        if (!isUploading) {
            setIsUploading(!isUploading);
            console.log('username ', username);
            console.log('password', password);

            try {
                axios
                    .post(`${process.env.REACT_APP_ADMIN_API}/auth/login`, {
                        username: username,
                        password: password,
                    })
                    .then((res) => {
                        const Token = res.data.jwt;
                        const permission = res.data.permission;
                        localStorage.setItem('token', Token);
                        localStorage.setItem('permission', permission);
                        console.log(res.data);
                        // Add a delay of 1.2 seconds before redirecting
                        setTimeout(() => {
                            window.location.replace('/');
                        }, 1200);
                    })
                    .catch((err) => {
                        console.error(err);
                        setIsUploading(false);
                        Swal.fire('Login Failed', 'Username or Password incorrect.', 'error');
                    });
            } catch (error) {
                console.log(error);
            }
        }
    }

    return (
        <main className="w-full h-screen" style={{ backgroundImage: backgroundImageUrl, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className="w-full h-full">
                <div className="w-full h-full grid grid-cols-12">
                    <div className="col-span-12 w-full h-full flex justify-center items-center">
                        <div className="grid grid-cols-12 bg-white/30 backdrop-blur-md w-11/12 h-[700px] rounded-3xl md:w-8/12">
                            <div className="bg-white rounded-3xl col-span-12 flex flex-col justify-center md:col-span-6">
                                <div className="w-full p-6 flex flex-col justify-between">
                                    <h1 className="text-[35px] text-gd-postperty md:text-[60px] text-center">Back-Office Grit</h1>
                                    <h3 className="text-[19px] md:text-4xl text-center">Grit Enterprise Co,.Ltd</h3>
                                </div>
                            </div>

                            <div className="col-span-12 md:col-span-6">
                                <div className="w-full flex flex-col justify-center md:p-6">
                                    <div className="w-full h-auto p-9 md:p-12">
                                        <h1 className="text-[30px] text-gd-postperty md:text-[60px]">ยินดีต้อนรับ</h1>
                                        <p className="text-sm text-white md:text-md">ทำตามนโยบายของบริษัทและรักษาความลับของลูกค้า</p>
                                        <p className="text-sm text-white md:text-md">Please adhere to the company policy </p>
                                    </div>

                                    <div className="w-full h-auto mt-0 md:mt-8 p-2.5">
                                        <div className="w-10/12 h-12 mx-auto">
                                            <p className="mb-2.5 text-white">ชื่อผู้ใช้งาน (username)</p>
                                            <input
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                type="text"
                                                placeholder="username"
                                                className="bg-white/30 outline-0 w-full text-white h-full rounded-xl text-center"
                                            />
                                        </div>

                                        <div className="w-10/12 h-12 mx-auto mt-12 relative">
                                            <p className="mb-2.5 text-white">รหัสผ่าน( password)</p>
                                            <input
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder="password"
                                                className="bg-white/30 outline-0 w-full text-white h-full rounded-xl text-center pr-10"
                                            />
                                            <span
                                                onClick={() => setShowPassword(!showPassword)}
                                                className="absolute right-3 top-[3.6rem] transform -translate-y-1/2 cursor-pointer"
                                            >
                                                {/* <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="text-white" /> */}
                                                <img
                                                    className="w-8 h-8"
                                                    src={
                                                        showPassword
                                                            ? '  https://cdn-icons-png.flaticon.com/512/159/159604.png  '
                                                            : '     https://cdn-icons-png.flaticon.com/512/10812/10812267.png '
                                                    }
                                                />
                                            </span>
                                        </div>

                                        <div className="w-10/12 h-auto mx-auto mt-12 text-white underline md:h-12">หากลืมรหัสผ่านกรุณาติดต่อผู้ดูแลระบบ</div>
                                        <div
                                            className={
                                                isUploading
                                                    ? 'hidden'
                                                    : 'w-10/12 h-12 mx-auto mt-2 bg-black text-white rounded-xl flex justify-center items-center cursor-pointer md:mt-0'
                                            }
                                            onClick={() => handleValidatePermission()}
                                        >
                                            <p>เข้าสู่ระบบ</p>
                                        </div>
                                        <div className="w-10/12 h-12 mx-auto flex justify-center items-center mt-2.5 ">{isUploading ? <PuffLoader color="#fff" /> : null}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
