import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

export default function CreateAccount() {
    const token  = localStorage.getItem("token")
    const [formData, setFormData] = useState({
        uname: '',
        password: '',
        role: 'admin',
        fname: '',
        mname: '-',
        lname: '',
        email: '',
    });

    const handleChange = (e: any) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/etc/gen-new-account`, formData ,{
                headers : {
                    Authorization : `Bearer ${token}`
                }
            });
            console.log('Account created successfully:', response.data);
            // Handle success (e.g., display a success message or redirect)
            Swal.fire('', 'สร้างบัญชีสำเร็จ', 'success');
        } catch (error :any) {
            console.error('Error creating account:', error);
            Swal.fire('ไม่สามารถสร้างบัญชีได้', `${error.response.data.error}`, 'error');
            // Handle error (e.g., display an error message)
        }
    };

    const renderRoleDetails = () => {
        switch(formData.role) {
            case 'superadmin':
                return <p>Super Admin ทำได้ทุกอย่าง</p>;
            case 'admin':
                return <p>Admin ทำได้ทุกอย่างยกเว้นเพิ่มแต้ม</p>;
            case 'staff':
                return <p>Staff ทำได้แค่แก้ไขกระเป๋าเงินและอนุมัติ KYC และใส่ป๊อปอัพ</p>;
            default:
                return null;
        }
    };

    return (
        <main className="w-full h-full">
            <div className="min-w-screen min-h-screen flex items-center justify-center px-5 py-5">
                <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden">
                    <div className="md:flex w-full">
                        <div className="flex justify-center items-center w-full md:block py-2.5 px-2.5">
                            <img src="/imgs/register.jpg" className="mx-auto rounded-xl" alt="Register Icon" />
                        </div>
                        <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
                            <div className="text-center mb-10">
                                <h1 className="font-bold text-3xl text-gray-900">สร้างบัญชี</h1>
                                <p>กรอกข้อมูลของคุณเพื่อสร้างบัญชี</p>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="flex -mx-3">
                                    <div className="w-full px-3 mb-5">
                                        <div className="flex">
                                            <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                                                <i className="mdi mdi-account-outline text-gray-400 text-lg"></i>
                                            </div>
                                            <input
                                                type="text"
                                                name="uname"
                                                value={formData.uname}
                                                onChange={handleChange}
                                                className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                                placeholder="ชื่อผู้ใช้"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex -mx-3">
                                    <div className="w-1/2 px-3 mb-5">
                                        <div className="flex">
                                            <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                                                <i className="mdi mdi-account-outline text-gray-400 text-lg"></i>
                                            </div>
                                            <input
                                                type="text"
                                                name="fname"
                                                value={formData.fname}
                                                onChange={handleChange}
                                                className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                                placeholder="ชื่อจริง"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-1/2 px-3 mb-5">
                                        <div className="flex">
                                            <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                                                <i className="mdi mdi-account-outline text-gray-400 text-lg"></i>
                                            </div>
                                            <input
                                                type="text"
                                                name="lname"
                                                value={formData.lname}
                                                onChange={handleChange}
                                                className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                                placeholder="นามสกุล"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex -mx-3">
                                    <div className="w-full px-3 mb-5">
                                        <div className="flex">
                                            <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                                                <i className="mdi mdi-email-outline text-gray-400 text-lg"></i>
                                            </div>
                                            <input
                                                type="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                                placeholder="example@gmail.com"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex -mx-3">
                                    <div className="w-full px-3 mb-5">
                                        <div className="flex">
                                            <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                                                <i className="mdi mdi-lock-outline text-gray-400 text-lg"></i>
                                            </div>
                                            <input
                                                type="password"
                                                name="password"
                                                value={formData.password}
                                                onChange={handleChange}
                                                className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                                placeholder="************"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex -mx-3">
                                    <div className="w-full px-3 mb-5">
                                        <div className="flex">
                                            <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                                                <i className="mdi mdi-account-outline text-gray-400 text-lg"></i>
                                            </div>
                                            <select
                                                name="role"
                                                value={formData.role}
                                                onChange={handleChange}
                                                className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                            >
                                                <option value="admin">Admin</option>
                                                <option value="superadmin">Superadmin</option>
                                                <option value="staff">Staff</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex -mx-3">
                                    <div className="w-full px-3 mb-5">
                                        {renderRoleDetails()}
                                    </div>
                                </div>
                                <div className="flex -mx-3">
                                    <div className="w-full px-3 mb-5">
                                        <button
                                            type="submit"
                                            className="block w-full max-w-xs mx-auto bg-indigo-500 hover:bg-indigo-700 focus:bg-indigo-700 text-white rounded-lg px-3 py-3 font-semibold"
                                        >
                                            ลงทะเบียนตอนนี้
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
