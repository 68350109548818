import axios from "axios";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";

type Status = "pending" | "success" | "processing";

type CardData = {
  ticket_id: string;
  title: string;
  content: string; // This could map to description from API
  imageUrl: string; // Assuming this is the first image URL from API images array
  status: Status;
  created_at: string; // Date of creation
  reporter_uid: string; // Reporter UID
};

interface statusStat {
  pending : string | number,
  processing : string  | number,
  success : string  | number,
}


const token = localStorage.getItem("token");

export default function Ticket() {
  const [status, setStatus] = useState<Status>("pending");
  const [cardDataList, setCardDataList] = useState<CardData[]>([]);
  const [searchIssue, setSearchIssue] = useState<string>("");
  const [statusStatCount, setStatusStatCount] = useState<statusStat >({
    pending : "0",
    processing : "0",
    success : "0",
  });


  function updateStatusTicket(newStatus : string , ticket_id : string ){
    console.log("new status" , newStatus)
    axios.post(`${process.env.REACT_APP_ADMIN_API}/manage/ticket/update`,{
      status: newStatus,
      ticket_id 
    },{
      headers : {
        Authorization : `Bearer ${token}`
      }
    }).then((res)=>{
      console.log(res.data)
      Swal.fire(``,`อัพเดทสถานะสำเร็จ ${ticket_id}` , 'success');
      getReportedData();
    }).catch((err)=>{
      console.log(err)
    })
  }
  const handleStatusChange = (index: number, newStatus: Status , ticket_id : string) => {
    const updatedCardDataList = [...cardDataList];
    updatedCardDataList[index].status = newStatus;
    setCardDataList(updatedCardDataList);
    updateStatusTicket(newStatus , ticket_id)
    // getStatStatus()
  };

//   const filteredCardDataList = cardDataList.filter((card) =>
//     card.title.toLowerCase().includes(searchIssue.toLowerCase())
//   );
  useEffect(() => {
    getReportedData();
    getStatStatus();
  }, [status , searchIssue]); 

  function getReportedData() {
    axios
      .get(
        `${
          process.env.REACT_APP_ADMIN_API
        }/manage/ticket/display?status=${status}${
          searchIssue !== "" ? `&keyword=${searchIssue}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // Assuming res.data is an array of tickets
        const fetchedCardDataList: CardData[] = res.data.map((ticket: any) => ({
          ticket_id: ticket.ticket_id,
          title: ticket.title,
          content: ticket.description,
          imageUrl: ticket.images.images[0], // Assuming first image URL from API
          status: ticket.status as Status,
          created_at: ticket.created_at,
          reporter_uid: ticket.reporter_uid,
        }));
        setCardDataList(fetchedCardDataList);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }

  useEffect(()=>{
    getStatStatus();
  },[])
  function getStatStatus() {
    axios.get(`${process.env.REACT_APP_ADMIN_API}/manage/ticket/count`,{
      headers : {
        Authorization : `Bearer ${token}`
      }
    }).then((res)=>{
      console.log(res.data)
      const data = res.data.status;
      setStatusStatCount(data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  return (
    <main className="w-full mx-auto h-screen bg-cover bg-center bg-black">
      <div className="w-full h-auto mx-auto mt-16 p-4 md:w-10/12">
        <div className="text-xl text-white">ปัญหาที่ลูกค้ารายงานเข้ามา</div>
        <div className="w-full h-auto flex flex-col mt-2.5 gap-x-2.5 mb-4 md:flex-row">
          <button
            className={`p-2 border rounded-l text-white ${
              status === "pending" ? "bg-purple-400 " : ""
            }`}
            onClick={() => setStatus("pending")}
          >
            {`รอการตรวจสอบ (${statusStatCount?.pending} รายการ)`}
          </button>

          <button
            className={`p-2 border rounded-l text-white ${
              status === "processing" ? "bg-purple-400 " : ""
            }`}
            onClick={() => setStatus("processing")}
          >
            {`กำลังดำเนินการ (${statusStatCount?.processing} รายการ)`}
            </button>
          <button
            className={`p-2 border rounded-l text-white ${
              status === "success" ? "bg-purple-500 " : ""
            }`}
            onClick={() => setStatus("success")}
          >
            {`ตรวจสอบแล้ว (${statusStatCount?.success} รายการ)`}
            </button>
          <input
            type="text"
            placeholder="ค้นหา Ticket"
            value={searchIssue}
            onChange={(e) => setSearchIssue(e.target.value)}
            className="p-2 mt-2.5 border rounded ml-auto w-full outline-none md:w-72"
          />
        </div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6 overflow-y-auto h-[700px] ">
          {cardDataList.map((card, index) => (
            <div
              key={index}
              className="border h-max p-4 rounded-lg mb-4 bg-white"
            >
              <h2 className="text-xl font-bold">{card.title}</h2>
              <p className="text-gray-700">หมายเลขธุรกรรม: {card.ticket_id}</p>
              <p className="my-2">{card.content}</p>
              <img
                src={card.imageUrl}
                alt="Error Screenshot"
                className="w-full h-auto mb-4"
              />
              <select
                value={card.status}
                onChange={(e) =>
                  handleStatusChange(index, e.target.value as Status , card.ticket_id)
                }
                className="p-2 border rounded"
              >
                <option value="pending">รอการตรวจสอบ</option>
                <option value="processing">กำลังดำเนินการ</option>
                <option value="success">ตรวจสอบแล้ว</option>
              </select>
              <p className="text-gray-700 mt-2">
                วันที่และเวลาที่สร้าง: {card.created_at}
              </p>
              <p className="text-gray-700">ผู้รายงาน: {card.reporter_uid}</p>
            </div>
          ))}
        </div>
      </div>
    </main>
  );
}
