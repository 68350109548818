// Modal component
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import LineNotify from "../../utility/LineNotify";
import countriesList from "../../data/countriesList.json";


interface ModalProps {
  closeModal: () => void;
  package_id: number | string; // Add package_id here
  packageName : string
}

interface User {
    user_id: string;
    package: string;
    cash: string;
    gcredit: string;
    roi: string;
    is_active: boolean;
    country_code : string;
  }
  

interface Admin {
  uname: string;
  uid: string;
  created_at: string;
  role: string;
  f_name: string | null;
  m_name: string | null;
  l_name: string | null;
  profile_image: string;
}

const Modal: React.FC<ModalProps> = ({ closeModal, package_id , packageName}) => {
  // State to manage the input value
  const [inputValue, setInputValue] = useState("");
  const [selectedUID, setSelectedUID] = useState("");
  const [selectedCountryID, setSelectedCountryID] = useState("");
  const [dataUsers, setDataUsers] = useState<User[]>([])
  const [allUsers, setAllUsers] = useState<User[]>([])
  const [adminData, setAdminData] = useState<Admin | null>(null);

  const token = localStorage.getItem("token")
  // Function to handle input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  function getAdminData(): any {
    const token = localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_ADMIN_API}/etc/my-profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const data = res.data;
        setAdminData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  function handleSelectUser(uid: string, countryCode: string ){
    setSelectedUID(uid);
    setSelectedCountryID(countryCode)
  }
  useEffect(()=>{
    getAdminData();
  },[])

  function getUser() {
    axios.get(`${process.env.REACT_APP_ADMIN_API}/etc/list-users?uid=${inputValue}`,{
        headers : {
            Authorization : `Bearer ${token}`
        }
    }).then((res)=>{
        // console.log("data user :" , res.data)
        setDataUsers(res.data)
        setAllUsers(res.data)
    }).catch((err)=>{
        console.log(err)
    })
  }

  useEffect(()=>{
    if(allUsers.length > 0) {
      const users = allUsers.filter((e) => {
        return e.user_id.includes(inputValue.toUpperCase())
      })
      setDataUsers(users)
    } else {
      getUser();
    }
  },[inputValue])
  // Function to handle form submission or API call
  const handleSubmit = () => {
    // Call your API here with the input value
    axios.post(`${process.env.REACT_APP_ADMIN_API}/manage/update/set-package`,{
        user_id : selectedUID,
        package_id : package_id
    },{
        headers : {
            Authorization : `Bearer ${token}`
        } 
    }).then(async(res)=>{
       await Swal.fire(``,`Add Package Successfully` , 'success');
       const countryName = selectedCountryID ? countriesList.find((e) => e.code === selectedCountryID) : null;
       const message = `\n\nAdmin : ${adminData?.uname}\n\nAdd Package For\nUser ID: ${selectedUID}\nCountry: ${countryName?.country} ${selectedCountryID ? `(${selectedCountryID})` : '-'}\nType of Package: ${packageName}`
       LineNotify(message,`${process.env.REACT_APP_TOKEN_LINE}`)
        // window.location.reload();
    }).catch((err)=>{
        console.log(err)
    })
  };


  
  return (
    <div className="fixed border border-red-500 inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="relative bg-white p-6 rounded-lg shadow-lg z-10 w-[1000px] h-[700px]">
      <button
          onClick={handleSubmit}
          className="w-11/12 absolute bottom-6 left-1/2 transform -translate-x-1/2 bg-black rounded-full  text-white hover:bg-purple-600 focus:outline-none  px-4 py-2"
        >
          Submit
        </button>
        <button
          onClick={closeModal}
          className="absolute right-2 top-2 bg-red-500 text-white bg-black-500 hover:bg-red-600 focus:outline-none rounded-full px-4 py-2"
        >
          Close
        </button>
        <h2 className="text-2xl mb-4">Add Package</h2>
        <p>Package ID: {package_id}</p> {/* Display the package_id */}
        <p>Package Name: {packageName}</p> {/* Display the package_id */}

        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Search User"
          className="w-full text-center  border  px-3 py-2  mb-4 outline-none rounded-full"
        />

        <div className="grid grid-cols-1 gap-x-2.5 w-full h-[400px] overflow-y-auto md:grid-cols-4">
            {dataUsers.map((data,idx)=>{
                return (
                    <div className={`w-full h-[200px] border p-2.5  rounded-xl cursor-pointer ${selectedUID === data.user_id ? "bg-purple-400 hover:bg-purple-400 text-white" : "hover:bg-gray-300"}`} onClick={()=>handleSelectUser(data.user_id , data.country_code)}>
                        <p>User ID : {data.user_id}</p>
                        <p>ROI : {data.roi}</p>
                        <p>G-Credit : {data.gcredit}</p>
                        <p>Cash : {data.cash}</p>
                        <p>Now Package : {data.package}</p>
                        <p className="">Active : {data.is_active ? "true" : "false"}</p>
                    </div>
                )
            })}
        </div>
    
      </div>
    </div>
  );
};

export default Modal;
