import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

interface MenuItems {
    name: string;
    src: string;
    link: string;
}

const ListMenu: MenuItems[] = [
    {
        name: 'verify KYC',
        src: '/imgs/icons/8392542.png',
        link: '/verify/kyc',
    },
    {
        name: 'Ads',
        src: '/imgs/icons/1055664.png',
        link: '/add/ads',
    },
    {
        name: 'Add Points',
        src: '/imgs/icons/5113089.png',
        link: '/add/points',
    },
    {
        name: 'Ticket',
        src: '/imgs/icons/4428490.png',
        link: '/report/tickets',
    },
    {
        name: 'Leader Package',
        src: '/imgs/icons/4389825.png',
        link: '/add/leader-package',
    },
    {
        name: 'Staking Package',
        src: '/imgs/icons/4389825.png',
        link: '/add/staking-package',
    },
    // {
    //   name: "wallet",
    //   src: "/imgs/icons/3258446.png",
    //   link: "/edit/wallet",
    // },
    {
        name: 'Edit Profile',
        src: '/imgs/icons/6826311.png',
        link: '/profile/edit',
    },
    {
        name: 'Create Account',
        src: '/imgs/icons/1144811.png',
        link: '/create/account',
    },
    {
        name: 'Reset 2FA Profile',
        src: '/imgs/icons/2fa.png',
        link: '/profile/reset-2fa',
    },
    {
        name: 'Queue Configuration',
        src: '/imgs/icons/queue.png',
        link: '/queue/configuration',
    },
    {
        name: 'Queue Priority',
        src: '/imgs/icons/queue2.png',
        link: '/queue/priority',
    },
];

const checkPermission = (permission: string, route: string): boolean => {
    const permissionMap: { [key: string]: string[] } = {
        superadmin: [
            '/verify/kyc',
            '/add/ads',
            '/add/points',
            '/report/tickets',
            '/add/leader-package',
            '/add/staking-package',
            '/edit/wallet',
            '/profile/edit',
            '/profile/reset-2fa',
            '/create/account',
            '/queue/configuration',
            '/queue/priority'
        ],
        admin: [
            '/verify/kyc',
            '/add/ads',
            '/report/tickets',
            '/add/leader-package',
            '/add/staking-package',
            '/edit/wallet',
            '/profile/edit',
            '/profile/reset-2fa'
        ],
        staff: [
            '/add/ads',
            '/edit/wallet',
            '/profile/edit',
            '/profile/reset-2fa',
            '/verify/kyc'
        ],
    };

    return permissionMap[permission]?.includes(route) ?? false;
};

export default function Index(): JSX.Element {
    const permission = localStorage.getItem('permission') || '';

    return (
        <main className="w-full h-full">
            <div className="h-full flex">
                <motion.div
                    className="flex flex-col justify-center gap-x-2.5  w-full h-max p-8 md:flex-row md:mt-12 flex-wrap"
                    //   drag="y" // Allow horizontal dragging
                >
                    {ListMenu.filter((data) => checkPermission(permission, data.link)).map((data, index) => {
                        return (
                            <Link to={`${data.link}`} className="p-2" key={index}>
                                <motion.div
                                    className="relative flex flex-col justify-center items-center h-56 mt-8 w-full cursor-pointer hover:bg-gray-100 rounded-2xl md:mt-0 md:w-72 border"
                                    whileHover={{ scale: 1.05 }} // Scale on hover
                                >
                                    <img src={data.src} alt={data.name} className="w-24 md:w-28" />
                                    <h2 className="text-xl mt-2.5 font-main">{data.name}</h2>
                                </motion.div>
                            </Link>
                        );
                    })}
                </motion.div>
            </div>
        </main>
    );
}
